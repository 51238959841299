import { KemiNextPage } from '@shared/types/next';
import InternalError from 'src/shared/components/InternalError';

const Custom500: KemiNextPage = () => {
  return <InternalError />;
};

Custom500.layoutType = 'KEMI';

export default Custom500;
